import { DateTime } from 'luxon';
import { Context } from '~/server/context';

type ScheduledJobWithTimezone = {
	start: Date;
	end: Date;
	completedAt?: Date | null;
	startedAt?: Date | null;
	startTimezoned?: string;
	endTimezoned?: string;
};

interface ITimestampedDBObj {
	updatedAt?: Date | null;
	createdAt: Date;
}

export class DateHelper {
	public static getBusinessLocalDateTime(date: Date, timezone: string) {
		return DateTime.fromJSDate(date, { zone: timezone });
	}

	public static getBusinessLocalNow(timezone: string) {
		return DateHelper.getBusinessLocalDateTime(new Date(), timezone);
	}

	public static getIsOnBusinessLocalDateQuery(date: Date, timezone: string) {
		const businessLocalDate = DateHelper.getBusinessLocalDateTime(date, timezone);
		return {
			gte: businessLocalDate.startOf('day').toJSDate(),
			lte: businessLocalDate.endOf('day').toJSDate(),
		};
	}

	public static getIsBetweenBusinessLocalDatesQuery(startDate: Date, endDate: Date, timezone: string) {
		return {
			gte: DateHelper.getBusinessLocalDateTime(startDate, timezone).startOf('day').toJSDate(),
			lte: DateHelper.getBusinessLocalDateTime(endDate, timezone).endOf('day').toJSDate(),
		};
	}

	public static localizeUTC(utcDate: Date, timezone: string) {
		return DateTime.fromJSDate(utcDate, { zone: timezone }).toJSDate();
	}

	public static localizeUTCSafe(utcDate: Date | null, timezone: string) {
		if (utcDate) {
			return DateTime.fromJSDate(utcDate, { zone: timezone }).toJSDate();
		}
		return utcDate;
	}

	public static roundToQuarterHour(date: Date) {
		const currentMinutes = date.getMinutes();
		const minutesToNextQuarterHour = 15 - (currentMinutes % 15);
		const minutesToLastQuarterHour = currentMinutes % 15;

		if (minutesToLastQuarterHour < minutesToNextQuarterHour) {
			date.setMinutes(currentMinutes - minutesToLastQuarterHour);
			return date;
		} else {
			date.setMinutes(currentMinutes + minutesToNextQuarterHour);
			return date;
		}
	}

	public static formatDate(date: string | Date, year = true, monthType: 'short' | 'long' | 'numeric' = 'long') {
		const dateObj = typeof date == 'string' ? new Date(date) : date;
		if (!year) {
			return dateObj.toLocaleDateString('en-US', { month: monthType, day: 'numeric' });
		} else {
			return dateObj.toLocaleDateString('en-US', { month: monthType, day: 'numeric', year: 'numeric' });
		}
	}

	public static formatTime(date: string | Date, hour12 = true, roundQuarterHour = false) {
		const dateObj = new Date(date);

		if (roundQuarterHour) {
			return DateHelper.roundToQuarterHour(dateObj).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12 });
		} else {
			return dateObj.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12 });
		}
	}

	public static getLocalTimeOfDayOnDate(date: Date, time: string) {
		const [hour, minute] = time.split(':');
		if (!hour || !minute) {
			return undefined;
		}
		return DateTime.fromJSDate(date)
			.set({ hour: parseInt(hour), minute: parseInt(minute) })
			.toJSDate();
	}

	public static getDaysInRange(isMultiDayJob: boolean, startDate: Date, endDate: Date, isOnlyOnWeekdays?: boolean) {
		const daysInRange: DateTime[] = [];
		const startDateTime = DateTime.fromJSDate(startDate);
		const endDateTime = DateTime.fromJSDate(endDate);

		if (isMultiDayJob) {
			let currentDate = startDateTime;
			while (currentDate <= endDateTime) {
				if (!isOnlyOnWeekdays) {
					daysInRange.push(currentDate);
				} else {
					// Docs say there should be .isWeekend(), but TS doesn't agree
					if (![6, 7].includes(currentDate.weekday)) daysInRange.push(currentDate);
				}

				currentDate = currentDate.plus({ day: 1 });
			}
		} else {
			daysInRange.push(startDateTime);
		}

		return daysInRange;
	}

	public static formatDBObjectDateTime(obj: ITimestampedDBObj, year = false, monthType: 'short' | 'long' | 'numeric' = 'long') {
		return obj.updatedAt
			? `${DateHelper.formatDate(obj.updatedAt, year, monthType)}, ${DateHelper.formatTime(obj.updatedAt)}`
			: `${DateHelper.formatDate(obj.createdAt, year, monthType)}, ${DateHelper.formatTime(obj.createdAt)}`;
	}

	// Keep in mind that during serialization timezones will be dropped, and these
	// dates will be sent back to the client as UTC.
	public static translateDatesForScheduledJob<T extends ScheduledJobWithTimezone>(scheduledJob: T, ctx: Context) {
		this.translateDates(scheduledJob, ctx.timezone);
	}

	public static translateDates<T extends ScheduledJobWithTimezone>(scheduledJob: T, timezone: string) {
		scheduledJob.start = DateHelper.localizeUTC(scheduledJob.start, timezone);
		scheduledJob.end = DateHelper.localizeUTC(scheduledJob.end, timezone);
		scheduledJob.completedAt = scheduledJob.completedAt && DateHelper.localizeUTCSafe(scheduledJob.completedAt, timezone);
		scheduledJob.startedAt = scheduledJob.startedAt && DateHelper.localizeUTCSafe(scheduledJob.startedAt, timezone);
		scheduledJob.startTimezoned = DateTime.fromJSDate(scheduledJob.start, { zone: timezone }).toString();
		scheduledJob.endTimezoned = DateTime.fromJSDate(scheduledJob.end, { zone: timezone }).toString();
	}

	public static isDateToday(_date: Date | string | null | undefined) {
		if (!_date) return false;

		const today = DateTime.now();
		const date = DateTime.fromJSDate(new Date(_date));
		const isToday = date.hasSame(today, 'day');

		return isToday;
	}

	/// NB: Different defaults than formatDate
	public static formatAsTodayOrDate(date: Date | string, year = false, monthType: 'short' | 'long' | 'numeric' = 'short') {
		const isToday = DateHelper.isDateToday(date);
		return isToday ? 'Today' : DateHelper.formatDate(date, year, monthType);
	}

	public static getDifferenceBetweenDateAndNow(date: Date) {
		// The time for many of the values in the database is off by the UTC offset, so for now we need to adjust the time accordingly.
		const offsetDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

		return Date.now() - offsetDate.getTime();
	}

	public static getTimeDifferenceShort(date?: Date) {
		if (!date) return undefined;

		const timeDifference = DateHelper.getDifferenceBetweenDateAndNow(date);
		const seconds = Math.floor(timeDifference / 1000);
		const minutes = Math.floor(seconds / 60);
		const hours = Math.floor(minutes / 60);
		const days = Math.floor(hours / 24);
		const weeks = Math.floor(days / 7);
		const months = Math.floor(weeks / 4.35);
		const years = Math.floor(months / 12);

		let timeAgo = 'Just now';

		if (years > 0) {
			timeAgo = `${years}y ago`;
		} else if (months > 0) {
			timeAgo = `${months}m ago`;
		} else if (weeks > 0) {
			return (timeAgo = `${weeks}w ago`);
		} else if (days > 0) {
			return (timeAgo = `${days}d ago`);
		} else if (hours > 0) {
			return (timeAgo = `${hours}h ago`);
		} else if (minutes > 0) {
			return (timeAgo = `${minutes}m ago`);
		} else if (seconds > 15) {
			return (timeAgo = `${seconds}s ago`);
		} else {
			return timeAgo;
		}
	}

	public static convertUNIXTimestampToDate(unixTimestamp: number) {
		return new Date(unixTimestamp * 1000);
	}
}
