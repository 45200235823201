import { Fragment, ReactNode, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { NavItemsObject } from '~/components/navigation/NavPropTypes';
import { useUserContext } from '~/react_context/UserContext';
import { useSideNavItemsForRole } from '~/layout/navItemConfig';
import { DrawerSidebar } from '../navigation/DrawerSidebar';
import { DesktopSidebar } from '../navigation/DesktopSidebar';
import { useRouter } from 'next/router';
import { useNavStore } from '~/stores/nav.store';

type ContainerNoCtxProps = {
	children: ReactNode;
	navItems: NavItemsObject;
	pathname?: string;
};

export const SideNavContainerNoCtx = ({ children, navItems, pathname }: ContainerNoCtxProps) => {
	const { collapsedNav: collapsed, setCollapsedNav: setIsCollapsed, sidebarOpen, setSidebarOpen } = useNavStore();

	return (
		<div className="h-full flex">
			{/* The drawer dialog nav that slides out on mobile */}
			<Transition.Root show={sidebarOpen} as={Fragment}>
				<Dialog as="div" className="relative z-[900] lg:hidden" onClose={setSidebarOpen}>
					<Transition.Child
						as={Fragment}
						enter="transition-opacity ease-linear duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="transition-opacity ease-linear duration-300"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
					</Transition.Child>

					<div className="fixed inset-0 flex z-40">
						<Transition.Child
							as={Fragment}
							enter="transition ease-in-out duration-300 transform"
							enterFrom="-translate-x-full"
							enterTo="translate-x-0"
							leave="transition ease-in-out duration-300 transform"
							leaveFrom="translate-x-0"
							leaveTo="-translate-x-full"
						>
							<Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full bg-menu-dark focus:outline-none">
								<Transition.Child
									as={Fragment}
									enter="ease-in-out duration-300"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="ease-in-out duration-300"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<div className="absolute top-0 right-0 -mr-12 pt-2">
										<button
											type="button"
											className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
											onClick={() => setSidebarOpen(false)}
										>
											<span className="sr-only">Close sidebar</span>

											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												strokeWidth={1.5}
												stroke="white"
												className="w-6 h-6"
											>
												<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m-15 0l15 15" />
											</svg>
										</button>
									</div>
								</Transition.Child>
								{/* Contents of sidebar */}
								<DrawerSidebar navItems={navItems} pathname={pathname} />
							</Dialog.Panel>
						</Transition.Child>
						<div className="flex-shrink-0 w-14" aria-hidden="true">
							{/* Force sidebar to shrink to fit close icon */}
						</div>
					</div>
				</Dialog>
			</Transition.Root>
			<DesktopSidebar navItems={navItems} pathname={pathname} />
			<div className="flex flex-col min-w-0 flex-1 overflow-hidden">
				{/* Horizontal nav for width < lg screens */}
				<div className="lg:hidden">
					<div className="flex items-center justify-between bg-menu-dark border-b border-primary-dark px-4 py-1.5">
						<div>
							<img className="h-8 w-auto" src="/glazier-sq-icon-600.png" alt="Glazier" />
						</div>
						<div>
							<button
								type="button"
								className="-mr-3 h-12 w-12 inline-flex items-center justify-center rounded-md text-white hover:text-gray-200 border border-white"
								onClick={() => setSidebarOpen(true)}
							>
								<span className="sr-only">Open sidebar</span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth={1.5}
									stroke="currentColor"
									className="w-6 h-6"
									aria-hidden="true"
								>
									<path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
								</svg>
							</button>
						</div>
					</div>
				</div>
				<div className="w-full h-full flex-1 relative z-auto flex overflow-hidden">
					{/* Page Content */}
					{children}
				</div>
			</div>
		</div>
	);
};

type SideNavContainerProps = {
	children: ReactNode;
	pathname?: string;
};

export const SideNavContainer = ({ children, pathname }: SideNavContainerProps) => {
	const { role, allowedPermissions } = useUserContext();
	const navItems = useSideNavItemsForRole(role, allowedPermissions);

	return (
		<SideNavContainerNoCtx navItems={navItems} pathname={pathname}>
			{children}
		</SideNavContainerNoCtx>
	);
};
