import { NextRequest } from 'next/server';

export const CookieName = 'feature-flags';

export enum FeatureFlag {
	SMART_TAKEOFF = 'smart_takeoff',
}

const flagValues = Object.values(FeatureFlag) as string[];

export interface WithFeatureFlags {
	featureFlags: FeatureFlag[];
}

export function getFeatureFlagsFromString(flags: string): FeatureFlag[] {
	return flags.split(',').filter((flag) => flagValues.includes(flag)) as FeatureFlag[];
}

export function getEnabledFeatureFlags(req: NextRequest): WithFeatureFlags {
	const cookieFeatureFlags: string = req.cookies[CookieName] ?? '';

	return {
		featureFlags: getFeatureFlagsFromString(cookieFeatureFlags),
	};
}

export function isFeatureEnabled(props: WithFeatureFlags, flag: FeatureFlag): boolean {
	return props.featureFlags?.includes(flag);
}
