import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { MainNavProps } from '~/components/navigation/NavPropTypes';
import { NavUserButton } from '~/components/navigation/NavUserButton';
import { useNavStore } from '~/stores/nav.store';
import { SidebarJobSearchComboBox } from '../form-fields/custom/SidebarJobSearchComboBox';
import { SidebarCreateJobButton } from '../pages/jobs/SidebarCreateJobButton';
import { SidebarLogo } from './SidebarLogo';
import { VerticalNav } from './VerticalNav';

export const DesktopSidebar = ({ navItems, pathname }: MainNavProps) => {
	const { collapsedNav: collapsed, setCollapsedNav } = useNavStore();

	const router = useRouter();
	const { pathname: routePathname } = router;

	const jobRoutePath = routePathname.includes('/jobs/[jobId]');

	useEffect(() => {
		if (jobRoutePath && !collapsed) {
			setCollapsedNav(true);
		} else if (!jobRoutePath && collapsed) {
			setCollapsedNav(false);
		}
	}, []);

	function onMouseEnter() {
		if (jobRoutePath) {
			setCollapsedNav(false);
		}
	}

	function onMouseLeave() {
		if (jobRoutePath) {
			setCollapsedNav(true);
		}
	}

	return (
		<div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className="hidden relative lg:flex lg:flex-shrink-0">
			<div
				className={clsx(`h-full border-r border-glazier-navy-500 bg-menu-dark transition-all duration-300 ease-in-out`, {
					'w-20': collapsed,
					'w-[320px]': !collapsed,
					'absolute z-[900]': jobRoutePath,
				})}
			>
				<div className="h-[178px] flex flex-col pt-4 pb-2">
					<SidebarLogo />
					<div
						className={clsx('flex flex-col justify-center items-center gap-4 mt-2', {
							'mx-4': !collapsed,
						})}
					>
						<div className="w-full flex justify-center">
							<SidebarJobSearchComboBox />
						</div>
						<div
							className={clsx('w-full', {
								'flex justify-center': collapsed,
							})}
						>
							<SidebarCreateJobButton />
						</div>
					</div>
				</div>
				<div
					className={clsx('h-[calc(100%-178px)] flex flex-col justify-between gap-4 w-full overflow-y-auto', {
						'overflow-y-hidden': collapsed,
					})}
				>
					<VerticalNav navItems={navItems} pathname={pathname} collapsed={collapsed} />
					<NavUserButton />
				</div>
			</div>
		</div>
	);
};
